
import * as React from 'react';
import { Button, Typography } from "@mui/material"
import { useChannel } from 'ably/react';

const URL_WEB_SOCKET = 'wss://localhost:8787/api/ws/echo';

export default function About() {

    const [counter, setCounter] = React.useState(0)

    // const interv = setInterval(() => {
    //     setCounter(counter + 1)
    //     clearInterval(interv)
    // }, 1000)

    // const timeout = setTimeout(() => {
    //     setCounter(counter + 1)
    // }, 1000)

    // const [ws, setWs] = React.useState<WebSocket | null>(null);
    // const [trades, setTrades] = React.useState([]);

    // React.useEffect(() => {
    //     const wsClient = new WebSocket(URL_WEB_SOCKET);
    //     wsClient.onopen = () => {
    //         setWs(wsClient);
    //         wsClient.send("mmmmmmmmmmmmmmmmmmmmmmmmmmm");
    //     };
    //     wsClient.onclose = () => console.log('ws closed');
    //     return () => {
    //         if (wsClient.readyState === 1) {
    //             wsClient.close();
    //         }
    //     };
    // }, []);

    // React.useEffect(() => {
    //     if (ws) {
    //         ws.onmessage = (evt) => {
    //             console.log("---------->", evt)
    //         };
    //     }
    // }, [ws])


    React.useEffect(() => {
        const itv = setInterval(() => {
            setCounter(prev => (prev + 1))
        }, 1000)
        return () => {
            clearInterval(itv)
        }
    }, [])

    const t = Math.round(counter * 1000 * Math.random())

    function handleClick(event): void {
        setCounter(0)
    }

    return <> <Button variant='contained' onClick={handleClick}> {counter} x Hello World! = {t}</Button>
    </>
}
