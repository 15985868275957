import { useUser } from "@clerk/clerk-react";
import {
    Cake as CakeIcon,
    Home as HomeIcon,
    Mail as MailIcon,
    Portrait as PortraitIcon
} from "@mui/icons-material";
import { AppBar, Box, Button, CssBaseline, Paper, Tab, Tabs, ThemeProvider, Toolbar, Typography, createTheme } from "@mui/material";
import { ChannelProvider } from "ably/react";
import React from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";

const barHeight = 50

const theme = createTheme({
    components: {
        MuiToolbar: {
            styleOverrides: {
                dense: {
                    height: barHeight,
                    minHeight: barHeight,
                }
            }
        },
        MuiListItemButton: {
            styleOverrides: {
                root: {
                    '&.Mui-selected': {
                        color: '#555',
                        backgroundColor: '#F0F3F3',
                    },
                    ':hover': {
                        fontWeight: 'bold',
                        backgroundColor: '#F0F3F3',
                    }
                },
            },
        },
        MuiListItemIcon: {
            styleOverrides: {
                root: {
                    'minWidth': 30
                }
            }
        },
        MuiTab: {
            styleOverrides: {
                root: {
                    'height': barHeight,
                    'minHeight': barHeight
                }
            }
        }
    },
})

const pageIcons = {
    '/home': <HomeIcon />,
    '/about': <MailIcon />,
    '/profile': <PortraitIcon />
}

export interface LayoutProps {
    routes: Array<{ label: string, path: string }>
}


const Layout = (props: LayoutProps) => {

    console.log("Rendering Layout")


    const { isSignedIn, isLoaded } = useUser()
    // const { pathname } = useLocation()

    if (!isLoaded) {
        return null
    }

    if (!isSignedIn) {
        return <Navigate to="/login" />
    }

    // const { pathname } = useLocation()

    const path = window.location.pathname
    if (path === "" || path === "/") {
        return window.location.replace("/home")
        // return <Navigate to="/home" replace/>
    }


    return <ThemeProvider theme={theme}>
        <CssBaseline />
        <AppBar position="fixed" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
            <Toolbar variant="dense">
                <CakeIcon sx={{ mr: 1 }} />
                <Typography noWrap component="div">
                    Good Cake App
                </Typography>
                {isSignedIn &&
                    <Button variant="contained" href="/logout" sx={{ ml: 'auto', zIndex: 999 }}>Logout</Button>
                }
            </Toolbar>
        </AppBar>
        <Box display='flex' flexDirection='row' sx={{ position: 'absolute', top: barHeight + 1, width: '100%', m: 0 }}>
            <Tabs
                orientation="vertical"
                variant="scrollable"
                value={path}
                sx={{ minWidth: 150 }}
            >
                {props.routes.map(({ label, path }, index) => {
                    return (<Tab key={path} label={<Typography noWrap>{label}</Typography>}
                        icon={pageIcons[path]} iconPosition="start" href={path} value={path} />)
                })}
            </Tabs>
            {/* <Stack sx={{ borderRight: '1px solid #eee'}}>
                <List sx={{ height:'100%' }}>
                    {props.routes.map(({ label, path }, index) => {
                        const isActive = path == pathname
                        const Caption = <Typography>{label}</Typography>
                        return (
                            <ListItem key={path} disablePadding>
                                <ListItemButton href={path} selected={isActive}>
                                    <ListItemIcon sx={{mx:0, px:0}}>
                                        {pageIcons[path]||<LensTwoToneIcon/>}
                                    </ListItemIcon>
                                    <ListItemText primary={Caption} sx={{mx:0, px:0}} />
                                </ListItemButton>
                            </ListItem>
                        )
                    })}
                </List>
                <Divider />
            </Stack> */}
            <Box flex={1} overflow='hidden' sx={{}}>
                <Paper sx={{ p: 1, m: 1 }} elevation={5}>
                    <ChannelProvider channelName="buzz-1">
                        <Outlet />
                    </ChannelProvider>
                </Paper>
            </Box>
        </Box>
        {/* 
        <Box sx={{ display: 'flex' }}>


            <Outlet />
        </Box> */}
    </ThemeProvider>
    // return (<div className="panel">
    //     <Outlet />
    // </div>)
}

export default Layout;

