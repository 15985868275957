import React from 'react';
import ReactDOM from 'react-dom/client';
// import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import { Auth0ProviderWithNavigate } from "./components/auth/Auth0ProviderWithNavigate.tsx"

import '@fontsource/roboto/300.css';
import "./assets/global.css"

import { ClerkProvider } from "@clerk/clerk-react"
import * as Ably from 'ably';

import { AblyProvider } from 'ably/react';

const PUBLISHABLE_KEY = process.env.REACT_APP_CLERK_PUBLISHABLE_KEY

// import { GoogleOAuthProvider } from '@react-oauth/google';

const root = ReactDOM.createRoot(document.getElementById('root'));

const ablyClient = new Ably.Realtime({ authUrl: '/api/ablytoken' });
// const ablyClient = Ably.Realtime.Promise({ authUrl: '/api/ablytoken'});

root.render(
  // <GoogleOAuthProvider clientId="928536940428-ffnmsfpi1ae4eel5gmndfmi0hvrrvbbj.apps.googleusercontent.com">
  <React.StrictMode>
    <BrowserRouter>
      {/* <Auth0ProviderWithNavigate> */}
      <ClerkProvider publishableKey={PUBLISHABLE_KEY}>
        <AblyProvider client={ablyClient}>
          <App />
        </AblyProvider>
        {/* </Auth0ProviderWithNavigate> */}
      </ClerkProvider>
    </BrowserRouter>
  </React.StrictMode>
  // </GoogleOAuthProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
