import React, { useEffect, useState } from "react"
import { Link, Paper, Typography } from "@mui/material"
// import { useAuth0 } from "@auth0/auth0-react"
import { SignedIn, SignedOut, useAuth, useUser } from "@clerk/clerk-react"
import { Image } from 'mui-image'

export const ProfilePage = () => {

    // const { isAuthenticated, user } = useAuth0()
    const { user, isSignedIn, isLoaded } = useUser()

    if (!isLoaded) {


        return <p>Not loaded</p>
    }

    if (!isSignedIn) {


        return <p>Not login</p>
    }


    return <div>
        <Typography variant="h5">{user.fullName}</Typography>
        <Image src={user.imageUrl} width={100} sx={{ borderRadius: 5 }} duration={1000}/>
        <Typography variant="h6"> {user.emailAddresses[0].emailAddress}</Typography>
    </div>

    // const { sessionId, userId, getToken } = useAuth()

    // const [token, setToken] = useState<string | null>()

    // useEffect(() => {
    //     const fetchToken = async () => {
    //         const t = await getToken()
    //         setToken(t)
    //     }
    //     fetchToken()
    // }, [])

    // return <>
    //     <SignedIn>
    //         <Typography variant="h5">{sessionId}, {userId}</Typography>
    //         <Typography sx={{ overflow:'hidden', wordWrap: "break-word" }}>
    //             {token}                
    //         </Typography>
    //         <Paper>

    //         </Paper>
    //     </SignedIn>
    //     <SignedOut>
    //         Please <Link href="/login">Login</Link>
    //     </SignedOut>
    // </>

}