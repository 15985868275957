import { useAuth0 } from "@auth0/auth0-react"
import React from "react"

const Callback = () => {
    const {user} = useAuth0()

    console.log("user======>", user)

    return <div>welcome <b>{user?.name}</b></div>
}

export default Callback