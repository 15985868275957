import { useAuth } from "@clerk/clerk-react"

export const LogoutPage = () => {
    // const { logout } = useAuth0()

    const { signOut } = useAuth()

    signOut(() => {
        window.location.replace("/login")
    })
}