import { SignIn, SignInButton, useSignIn } from "@clerk/clerk-react"
import { OAuthStrategy } from "@clerk/types";
import { Box, Button, Typography } from "@mui/material"
import React from "react"
import GoogleIcon from '@mui/icons-material/Google';
import { GoogleButton } from "../../components/auth/GoogleLoginButton.tsx";

const Login = () => {

    // const { loginWithRedirect } = useAuth0()

    // const handleLogin = async () => {
    //     await loginWithRedirect({
    //         appState: {
    //             returnTo: "/profile",
    //         },
    //     })
    // }

    // return <Button onClick={handleLogin}>Login</Button>

    // const { signIn } = useSignIn();

    // const signInWith = (strategy: OAuthStrategy) => {
    //     return signIn?.authenticateWithRedirect({
    //         strategy,
    //         redirectUrl: "/whatever",
    //         redirectUrlComplete: "/profile",
    //     });
    // };

    return <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="90vh"
    >
        <Box display="flex" flexDirection="column">
            <Typography variant="h2">Hello</Typography>
            {/* <SignInButton afterSignInUrl="/profile" >
                <Button variant="contained">Login</Button>
            </SignInButton> */}
            {/* <SignIn /> */}
            {/* <Button variant="contained" onClick={() => { signInWith('oauth_google') }} > <GoogleIcon fontSize="small" /> Login</Button> */}
            <GoogleButton />
        </Box>
    </Box>

    // 

}

export default Login