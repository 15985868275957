import React from 'react';
// import { GoogleLogin } from '@react-oauth/google';
import { Routes, Route, Navigate, useLocation } from "react-router-dom"
import Layout from "./pages/layout.tsx"
import Home from "./pages/home.tsx"
import About from "./pages/about.tsx"
import NotFound from './pages/errors/404.jsx';
import CallbackPage from "./pages/auth/callback.tsx"
import LoginPage from "./pages/auth/login.tsx"
import { LogoutPage } from './pages/auth/logout.tsx';
import { ProfilePage } from './pages/profile.tsx';
import {AuthenticateWithRedirectCallback} from "@clerk/clerk-react"


function App() {
    // const responseMessage = (response) => {
    //     console.log(response);
    // };
    // const errorMessage = (error) => {
    //     console.log(error);
    // };
    // return (
    //     <div>
    //         <h2>React Google Login</h2>
    //         <br />
    //         <br />
    //         <GoogleLogin onSuccess={responseMessage} onError={errorMessage} />
    //     </div>
    // )
    // const TabContext = createContext()


    const routes = [
        { label: "Home", path: "/home", page: <Home /> },
        { label: "About", path: "/about", page: <About /> },
        { label: "Profile", path: "/profile", page: <ProfilePage /> },
    ]

    // const { pathname } = useLocation()


    return <Routes>
        <Route path="" element={<Layout routes={routes} />} >
            {/* <Route index element={<Navigate to={routes[0].path} />} /> */}
            {routes.map(({ label, path, page }, index) => (
                <Route key={path} path={path} element={page} />
            ))}
        </Route>
        <Route path="/login" element={<LoginPage />} />
        <Route path="/logout" element={<LogoutPage />} />
        <Route path="/auth_callback" element={<AuthenticateWithRedirectCallback />} />
        <Route path="*" element={<NotFound />} />
    </Routes>

}
export default App;